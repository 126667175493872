/* Desktop first
0 - 600px       Phone
600 - 900px     Tablet portrait
900 - 1200px    Tablet landscape
[1200-1800px]   Normal styles apply, desktop
1800px+         BIG desktop
*/
/*
$breakpoint arguments:
- phone    
- tab-port
- tab-land 
- big-desktop

ORDER: 
- base+typography
- general layout + grid
- page layout
- components

1em=16px if using standard browser setting
Use ems rather than rems for media breakpoints
Use ems rather than px for media breakpoints as we want the website to be responsive to the user changing the font size!
*/
*,
*::before,
*::after {
  margin: 0px;
  padding: 0px;
  box-sizing: inherit; }

html {
  font-size: 62.5%; }
  @media (max-width: 37.5em) {
    html {
      font-size: 50%; } }

body {
  box-sizing: border-box;
  overflow-y: hidden; }

body {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  line-height: 1.7;
  color: #777; }

.rhap_time {
  font-size: 1.2rem; }

.u-center-text {
  text-align: center; }

.u-margin-bottom-small {
  margin-bottom: 1.5rem; }

.u-margin-bottom-medium {
  margin-bottom: 4rem; }

.u-margin-bottom-big {
  margin-bottom: 8rem; }

.u-margin-top-small {
  margin-top: 1.5rem; }

.u-margin-top-medium {
  margin-top: 4rem; }

.u-margin-top-big {
  margin-top: 8rem; }

.u-margin-top-huge {
  margin-top: 10rem; }

.u-hidden {
  opacity: 0; }

.table-row__wrapper {
  padding: 0 1rem; }
  .table-row__wrapper:nth-child(even) {
    background-color: #e6e4e4; }
  @media (max-width: 37.5em) {
    .table-row__wrapper {
      padding: 1rem; } }

.table-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 100;
  position: relative; }
  @media (max-width: 37.5em) {
    .table-row {
      flex-direction: column; } }
  .table-row__currently-playing-collapse-open {
    transition: height 0.4s ease; }
  .table-row__currently-playing-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    opacity: 1;
    position: relative;
    padding: 0.5rem 0; }
    .table-row__currently-playing-wrapper--invisible {
      height: 0;
      opacity: 0;
      display: none; }
  .table-row__current-time, .table-row__duration {
    width: 3.5rem;
    font-size: 1.2rem; }
  .table-row__current-time {
    text-align: left; }
  .table-row__duration {
    text-align: right;
    margin-right: 1rem;
    margin-left: 1rem; }
  .table-row__progress-bar-wrapper {
    position: relative;
    flex-grow: 1; }
  .table-row__currently-playing, .table-row__progress-bar {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid rgba(201, 201, 201, 0.5); }
  .table-row__currently-playing {
    z-index: 1;
    background-color: transparent;
    cursor: pointer; }
  .table-row__currently-playing:hover ~ .table-row__progress-bar--thumb {
    opacity: 1; }
  .table-row__progress-bar {
    z-index: 0;
    width: 7px;
    background-color: rgba(120, 120, 120, 0.5); }
    .table-row__progress-bar--thumb {
      cursor: pointer;
      position: absolute;
      left: 0;
      top: -0.2rem;
      height: 2.4rem;
      width: 0.7rem;
      z-index: 4; }
      .table-row__progress-bar--thumb-svg {
        filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.2)); }
      .table-row__progress-bar--thumb:hover {
        opacity: 1; }
    .table-row__progress-bar:hover .table-row__progress-bar--thumb {
      opacity: 1; }
  .table-row__icon, .table-row__info-icon-img {
    vertical-align: middle; }
  .table-row__icon, .table-row__flag {
    width: 2.2rem; }
  .table-row__icon {
    margin: 0 1rem 0 0; }
  .table-row__info-icon {
    margin-right: 0.5rem; }
  .table-row__info-icon-img {
    width: 2rem; }
  .table-row__metaData {
    display: flex;
    flex-direction: column;
    margin: 0.4rem 0;
    width: 30%; }
    @media (max-width: 75em) {
      .table-row__metaData {
        width: 40%; } }
    @media (max-width: 37.5em) {
      .table-row__metaData {
        width: 100%; } }
  .table-row__song, .table-row__composer, .table-row__lyricsAuthor {
    display: flex;
    flex-direction: row;
    align-items: center; }
  .table-row__song {
    width: 50%;
    align-self: stretch;
    font-size: 1.6rem;
    font-weight: 700; }
    @media (max-width: 75em) {
      .table-row__song {
        width: 60%; } }
    @media (max-width: 37.5em) {
      .table-row__song {
        width: 100%;
        margin-left: -1rem; } }
  .table-row__songID {
    width: 2.2rem; }
  .table-row__songName {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 90%; }
  .table-row__songLanguage, .table-row__composer-country, .table-row__lyricsAuthor-country {
    margin-left: 0.2rem;
    display: flex; }
  @media (max-width: 75em) {
    .table-row__composer, .table-row__lyricsAuthor {
      width: 100%; } }
  @media (max-width: 75em) {
    .table-row__composer {
      width: 100%;
      margin-right: 0; } }
  .table-row__composer-name, .table-row__lyricsAuthor-name {
    width: 100%; }
    @media (max-width: 56.25em) {
      .table-row__composer-name, .table-row__lyricsAuthor-name {
        font-size: 1.2rem; } }
  .table-row__composer-country, .table-row__lyricsAuthor-country {
    box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.4); }
  .table-row__description {
    padding: 1rem 0; }
  .table-row a:link,
  .table-row a:visited {
    display: inline-block;
    text-decoration: none;
    color: #5643fa;
    position: relative;
    font-weight: 600; }
  .table-row a:hover {
    transform: scale(1.2);
    text-shadow: 0px 1px 4px rgba(255, 255, 255, 0.2); }

.rhap_container {
  padding: 0 !important;
  background-color: transparent !important;
  box-shadow: 0 0 0 !important; }

.rhap_play-pause-button {
  z-index: 0;
  font-size: 4rem !important;
  width: 4rem !important;
  height: 4rem !important; }

.rhap_controls-section {
  margin-top: 0 !important; }

.rhap_main {
  place-content: flex-start !important; }

.rhap_progress-section {
  display: none !important; }

.cont-play {
  text-align: right; }
  .cont-play__checkbox {
    margin-left: 0.7rem; }

.subcategory {
  font-size: 1.4rem;
  font-weight: 400;
  margin: 0rem auto 0 auto;
  backface-visibility: hidden;
  transform-origin: top;
  transition: height 400ms ease;
  padding-left: 0.5rem; }
  .subcategory__songlist {
    border-top: 1px solid rgba(201, 201, 201, 0.5);
    border-left: 1px solid rgba(201, 201, 201, 0.5);
    padding: 0;
    margin: 0 0 2rem 1rem;
    box-shadow: 1rem 1rem 1rem rgba(0, 0, 0, 0.2); }
  .subcategory--hidden {
    height: 0;
    margin: 0; }
  .subcategory__container0, .subcategory__container1, .subcategory__container2, .subcategory__container3, .subcategory__container4, .subcategory__container5, .subcategory__container6 {
    margin-left: 2px;
    position: relative; }
  .subcategory__container-line {
    width: 2px;
    background-color: #c9c9c9;
    position: absolute;
    top: 2.8rem;
    height: calc(100% - 2.8rem); }
    @media (max-width: 37.5em) {
      .subcategory__container-line {
        top: 2.5rem;
        height: calc(100% - 2.5rem); } }
  .subcategory__title-container {
    display: flex;
    justify-content: center;
    align-content: center;
    position: relative;
    margin-top: 2rem; }
  .subcategory__title-line {
    position: absolute;
    left: 0;
    top: 50%;
    height: 2px;
    width: 50%;
    background-color: #c9c9c9; }
  .subcategory__title {
    background-size: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem 2rem;
    font-size: 2rem;
    cursor: pointer;
    justify-content: center;
    z-index: 10;
    background: white; }
    @media (max-width: 37.5em) {
      .subcategory__title {
        font-size: 1.6rem; } }
  .subcategory__description {
    margin: 0rem 1rem 2rem 1rem; }
    .subcategory__description--shallow {
      margin: 0rem 1rem -1rem 1rem; }
  .subcategory__checkbox {
    display: none; }
  .subcategory__collapse-button {
    margin: 0 1.5rem 0 0;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: 700;
    float: right;
    transition: transform 400ms ease; }
  .subcategory__checkbox:checked ~ .subcategory__collapse-button {
    transform: rotate(-180deg); }

.collapse-icon {
  margin: 0 1.5rem 0 0;
  cursor: pointer;
  width: 1.8rem;
  fill: #8e8e8e;
  float: right;
  transition: transform 400ms ease; }
  .collapse-icon:hover {
    margin-bottom: 2px;
    filter: drop-shadow(0px -1px 1px rgba(0, 0, 0, 0.4)); }
  .collapse-icon-reverse {
    transform: rotate(-180deg); }

.color-slider {
  margin: 0 1rem 0 4rem;
  display: flex;
  flex-direction: row;
  align-items: center; }
  .color-slider__slider {
    position: relative;
    border-radius: 1rem;
    height: 1rem;
    background-image: linear-gradient(90deg, red 0%, yellow 50%, lime 100%);
    width: 20rem;
    filter: brightness(0.6); }
  .color-slider__knob {
    position: absolute;
    left: 50%;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: yellow;
    transform: translate(-25%, -25%);
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.4)); }
  .color-slider__thumbs-up {
    font-size: 3rem;
    margin-left: 2rem;
    color: yellow;
    filter: drop-shadow(0 0 2px black);
    transform: rotate(90deg); }
  .color-slider__result {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background-color: yellow;
    margin-left: 1rem;
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.4)); }

.stars {
  width: 20%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: 2rem; }
  .stars__container {
    position: relative;
    cursor: pointer;
    display: flex;
    align-content: center;
    margin: 0 1rem; }
  .stars__star {
    height: 2.5rem; }
  .stars__ranking-avg {
    margin-left: 0.1rem; }
  .stars__5grey {
    height: 2.5rem; }
  .stars__5yellow {
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.2));
    position: absolute;
    height: 2.5rem;
    top: 0;
    left: 0;
    overflow: hidden;
    object-fit: cover;
    width: 0%;
    object-position: 0 0; }

.head {
  position: fixed;
  width: 100%;
  background-image: linear-gradient(to right, #5643fa, #2998ff);
  height: 5rem;
  padding: 1rem 3rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.4); }
  @media (max-width: 37.5em) {
    .head {
      height: 5rem;
      padding: 0 1rem; } }
  .head__logobox {
    align-self: center; }
  .head__logo {
    height: 3rem;
    display: block;
    opacity: 0.9;
    filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.7)); }
    @media (max-width: 56.25em) {
      .head__logo {
        height: 3rem;
        opacity: 1; } }
  .head__userbox {
    justify-self: flex-end;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    flex-grow: 1;
    opacity: 0.9; }
    @media (max-width: 56.25em) {
      .head__userbox {
        opacity: 1; } }
  .head__user-icon {
    height: 3rem;
    margin-right: 1rem;
    align-self: center;
    filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.5)); }
    @media (max-width: 56.25em) {
      .head__user-icon {
        height: 1.5rem; } }
  .head__user {
    align-self: center;
    font-size: 1.4rem;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff; }
    @media (max-width: 56.25em) {
      .head__user {
        font-size: 1.2rem; } }

.footer {
  position: fixed;
  width: 100%;
  background-image: linear-gradient(to right, #5643fa, #2998ff);
  height: 5rem;
  padding: 1rem 3rem;
  bottom: 0;
  font-size: 1.2rem;
  color: #f7f7f7;
  display: flex;
  flex-direction: row;
  align-content: center;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);
  box-shadow: 0 -1rem 2rem rgba(0, 0, 0, 0.4); }
  @media (max-width: 56.25em) {
    .footer {
      height: 3rem; } }
  @media (max-width: 37.5em) {
    .footer {
      font-size: 1rem;
      padding: 1rem 1rem; } }
  .footer__content {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between; }
  .footer__copyright-notice {
    justify-self: flex-start;
    justify-content: flex-start;
    align-self: center; }
  .footer__version {
    justify-self: flex-end;
    justify-content: flex-end;
    align-self: center; }
  .footer__release-notes {
    margin-left: 1rem;
    align-self: flex-end; }
    .footer__release-notes:link, .footer__release-notes:visited {
      color: #f7f7f7; }

.main {
  width: 100vw;
  height: calc(100vh - 5rem - 5rem);
  position: fixed;
  top: 5rem;
  overflow-y: scroll;
  padding: 5rem;
  font-size: 1.4rem;
  box-shadow: 0 1rem 4rem rgba(0, 0, 0, 0.2);
  z-index: -4; }
  .main__h1 {
    font-size: 4rem;
    margin-bottom: 2rem;
    font-weight: 400; }
    @media (max-width: 56.25em) {
      .main__h1 {
        font-size: 3.8rem; } }
  @media (max-width: 56.25em) {
    .main {
      padding: 1rem;
      height: calc(100vh - 5rem - 3rem);
      top: 5rem; } }

.container {
  width: 80%;
  margin: 0 auto;
  margin-bottom: 24rem; }
  @media (max-width: 37.5em) {
    .container {
      width: 100%;
      padding: 1rem 2rem; } }

.intro {
  display: flex;
  flex-direction: row;
  padding: 2rem; }
  @media (max-width: 37.5em) {
    .intro {
      flex-direction: column; } }
  .intro__content {
    margin-bottom: 3rem; }
  .intro__participants {
    margin-top: 2rem; }
  .intro__participants-listitem {
    display: flex;
    flex-direction: row;
    align-content: center;
    margin: 0.5rem 0; }
  .intro__participants-logobox {
    width: 3rem;
    justify-self: center;
    text-align: center; }
  .intro__participants-logo {
    height: 2rem;
    justify-self: center;
    opacity: 0.7; }
  .intro__participants-name {
    align-self: center;
    margin-left: 0.5rem; }
  .intro__h2-wrapper {
    display: flex;
    align-content: center; }
  .intro__checkbox {
    display: none; }
  .intro__collapse-button {
    margin-left: 0.2rem;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: 700;
    transform: scaleX(1.5);
    transition: transform 400ms ease;
    align-self: center; }
  .intro__checkbox:checked ~ .intro__collapse-button {
    transform: rotate(180deg) scaleX(1.5); }
  .intro__collapse-button::before {
    content: "\22C0"; }
  .intro__pictures {
    display: flex;
    flex-direction: row; }
    @media (max-width: 75em) {
      .intro__pictures {
        flex-direction: column;
        position: relative; } }
    @media (max-width: 37.5em) {
      .intro__pictures {
        top: 0; } }
    .intro__pictures--only-1 {
      margin-left: 0rem; }
      @media (max-width: 75em) {
        .intro__pictures--only-1 {
          margin-left: 0; } }
  .intro__picture {
    position: relative; }
  .intro__img {
    height: 30rem;
    position: relative;
    top: 0rem; }
    @media (max-width: 75em) {
      .intro__img {
        top: 0;
        height: auto;
        width: 100%; } }
  @media (max-width: 75em) {
    .intro__pictures--only-1 .intro__img {
      height: 20rem;
      width: auto; } }
  @media (max-width: 37.5em) {
    .intro__pictures--only-1 .intro__img {
      top: 0;
      height: auto;
      width: 100%; } }
  .intro__img2 {
    position: relative;
    top: -5rem; }
    @media (max-width: 75em) {
      .intro__img2 {
        left: 5rem;
        top: 0; } }
    @media (max-width: 56.25em) {
      .intro__img2 {
        left: 0; } }

.infoWin {
  position: fixed;
  right: 0px;
  top: 0px;
  width: 20rem;
  height: fit-content;
  border: 1px solid black;
  border-radius: 5px;
  box-shadow: 0 1rem 4rem rgba(0, 0, 0, 0.2);
  font-size: 1.2rem;
  background-color: orangered;
  color: white;
  margin: 2rem;
  display: none; }
